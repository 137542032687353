<template>
  <div class="container mt-5">
    <div class="row mt-5">
      <div class="col">
        <h1>Quickstart Guide</h1>
        <h3>How to update your payment information</h3>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <base-collapsable :faq-items="this.payFaqItems"></base-collapsable>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCollapsable from "@/components/BaseCollapsable";

export default {
  name: "SupportView",
  components: { BaseCollapsable },
  data() {
    return {
      isActive: false,
      payFaqItems: [
        {
          question:
            "1.- Sign in to the platform , if you have not done it already",
          images: [
            {
              url: "https://bujin-videos-encoded.s3.amazonaws.com/tenant0851fd69-1b37-4530-a80c-3676817e9472/output/media/paysteps/pay-step-1.jpg",
            },
            {
              url: "https://bujin-videos-encoded.s3.amazonaws.com/tenant0851fd69-1b37-4530-a80c-3676817e9472/output/media/paysteps/pay-step-2.jpg",
            },
          ],

          answer: "",
          tip: " - click to view image",
        },
        {
          question:
            '2.- Visit your "Account Settings" section from your mobile or computer ',
          images: [
            {
              url: "https://bujin-videos-encoded.s3.amazonaws.com/tenant0851fd69-1b37-4530-a80c-3676817e9472/output/media/paysteps/pay-step-3.jpg",
            },
          ],

          answer: "",
          tip: " - click to view image",
        },
        {
          question: '3.- Click on "Plans & Billing Information" Button.',
          images: [
            {
              url: "https://bujin-videos-encoded.s3.amazonaws.com/tenant0851fd69-1b37-4530-a80c-3676817e9472/output/media/paysteps/pay-step-4.jpg",
            },
          ],
          answer: "",
          tip: " - click to view image",
        },
        {
          question:
            '4.- In the Payment Methods section click on "Add Payment Method"',
          images: [
            {
              url: "https://bujin-videos-encoded.s3.amazonaws.com/tenant0851fd69-1b37-4530-a80c-3676817e9472/output/media/paysteps/pay-step-5.jpg",
            },
          ],

          answer: "",
          tip: " - click to view image",
        },
        {
          question: "5.- Enter your new debit or credit card info. \n",
          images: [
            {
              url: "https://bujin-videos-encoded.s3.amazonaws.com/tenant0851fd69-1b37-4530-a80c-3676817e9472/output/media/paysteps/pay-step-6.jpg",
            },
          ],

          answer: "",
          tip: " - click to view image",
        },
      ],
      faqItems: [
        {
          question: "What is Bujin.tv?",
          images: [],
          answer:
            "Bujin.tv is a streaming service for martial arts fans everywhere. Featuring instructional videos, seminars, documentaries, interviews, podcasts and movies. From top notch instructors and video producers around the world.You can watch what you want, when you want, with no annoying ads or commercials – all for one low monthly fee. We will keep adding new videos every week so there will always be more to discover and enjoy.",
        },
        {
          question: "How much does Bujin.tv cost?",
          images: [],
          answer:
            "Bujin.tv is accessible via your PC, smartphone and other devices all for one fixed monthly fee. Just $9.99 a month or $99.99 for one year (pre-tax). No extra costs, no contracts. Your first 7 days are a free trial.",
        },
        {
          question: "What can I watch on Bujin.tv?",
          images: [],
          answer:
            "Bujin.tv is building a carefully selected catalog of top quality martial arts related videos. We bring together content from leading instructors and producers from around the world. We work with our content partners to bring their best content onto Bujin.tv and we invest in producing new original and exclusive content for our service. You will discover videos from some of the top names in online martial arts on Bujin.tv.",
        },
        {
          question: "How do I cancel?",
          images: [],
          answer:
            "If you change your mind it's easy to cancel. There are no contracts and no commitments. You can easily cancel your account via links on your Account page. There are no cancellation fees – start or stop your account anytime.",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
